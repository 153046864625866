export default function logoBehaviour() {
  const customLogo = document.querySelector('.custom-logo');
  const mainLogo = document.querySelector('.main-logo');

  if (document.body.scrollTop > 220 || document.documentElement.scrollTop > 220) {
    customLogo.classList.add('show');
    mainLogo.classList.add('hide');
  } else {
    customLogo.classList.remove('show');
    setTimeout(function() {
      mainLogo.classList.remove('hide');
    }, 300);
  }
}
