export default function fontObserver() {
  const FontFaceObserver = require('fontfaceobserver');

  const fontOpenSans = new FontFaceObserver('open_sans');
  const fontOptiLatin = new FontFaceObserver('optilatin-elongated');
  const fontRoboto = new FontFaceObserver('Roboto');

  // fontOpenSans.load().then(function() {
  //   console.log('Open Sans has loaded');
  // });
  // fontOptiLatin.load().then(function() {
  //   console.log('OptiLatin has loaded');
  // });
}
