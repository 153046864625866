export default function blocksTop() {
  const blocksArr = Array.from(document.querySelectorAll('.on-top-block'));

  blocksArr.forEach(function(block) {

    block.addEventListener('click', function() {
       blocksArr.forEach(function(block) {
        if (block.classList.contains('show-description')) {
          block.classList.remove('show-description');
          }
       })

      this.classList.add('show-description');
    });
  });
}
